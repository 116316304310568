import request from './request.js'
export function getflexibleCodeList(params) {//获取
    return request({
        url: "/config/flexibleCode/list.do?",
        method: 'post',
        data: params
    })
}
export function deleteflexibleCodeList(params) {//获取
    return request({
        url: "/config/flexibleCode/deleteById.do?",
        method: 'post',
        data: params
    })
}

export function updateFlexibleCodeList(params) {//获取
    return request({
        url: "/config/flexibleCode/update.do?",
        method: 'post',
        data: params
    })
}

export function addFlexibleCodeList(params) {//获取
    return request({
        url: "/config/flexibleCode/add.do?",
        method: 'post',
        data: params
    })
}
export function decodeURL(params) {//获取
    return request({
        url: "/qrcode/decode.do?",
        method: 'post',
        data: params,
        timeout:20000,
        headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function getAgentAssociationList(params) {//代理商关联列表
    return request({
        url: "/config/agent/list.do?",
        method: 'post',
        data: params
    })
}


export function getTypeList(params) {//获取
    return request({
        url: "/config/typeList.do?",
        method: 'post',
        data: params
    })
}
export function typeSelectList(params) {//获取
    return request({
        url: "/config/typeSelectList.do?",
        method: 'post',
        data: params
    })
}

export function addAgentAssociation(params) {//获取
    return request({
        url: "/config/agent/add.do?",
        method: 'post',
        data: params
    })
}
export function updateAgentAssociation(params) {//获取
    return request({
        url: "/config/agent/update.do?",
        method: 'post',
        data: params
    })
}
export function deleteAgentAssociation(params) {//获取
    return request({
        url: "/config/agent/deleteById.do?",
        method: 'post',
        data: params
    })
}




export function getCardAssociationList(params) {//代理商关联列表
    return request({
        url: "/config/card/list.do?",
        method: 'post',
        data: params
    })
}

export function addCardAssociation(params) {//获取
    return request({
        url: "/config/card/add.do?",
        method: 'post',
        data: params
    })
}
export function updateCardAssociation(params) {//获取
    return request({
        url: "/config/card/update.do?",
        method: 'post',
        data: params
    })
}
export function deleteCardAssociation(params) {//获取
    return request({
        url: "/config/card/deleteById.do?",
        method: 'post',
        data: params
    })
}

export function getCommonConfigList(params) {//获取
    return request({
        url: "/config/value/list.do?",
        method: 'post',
        data: params
    })
}

export function addCommonConfig(params) {
    return request({
        url: "/config/value/add.do?",
        method: 'post',
        data: params
    })
}

export function updateCommonConfig(params) {
    return request({
        url: "/config/value/update.do?",
        method: 'post',
        data: params
    })
}

export function delCommonConfig(params) {
    return request({
        url: "/config/value/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function numbelList(params) {
    return request({
        url: "/number/set/list.do?",
        method: 'get',
        params: params
    })
}

export function addLibrary(params) {
    return request({
        url: "/number/set/add.do?",
        method: 'post',
        data: params
    })
}
export function delLibrary(params) {
    return request({
        url: "/number/set/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function showNo(params) {//获取号码库号码列表
    return request({
        url: "/number/set/showNo.do?",
        method: 'get',
        params: params
    })
}

export function importNo(params) {
    return request({
        url: "/number/set/importNo.do?",
        method: 'post',
        data: params
    })
}


export function getNumLibList(params) {//获取
    return request({
        url: "/config/set/list.do?",
        method: 'post',
        data: params
    })
}


export function addLibAssociation(params) {
    return request({
        url: "/config/set/add.do?",
        method: 'post',
        data: params
    })
}

export function updateLibAssociation(params) {
    return request({
        url: "/config/set/update.do?",
        method: 'post',
        data: params
    })
}

export function delLibAssociation(params) {
    return request({
        url: "/config/set/deleteById.do?",
        method: 'post',
        data: params
    })
}


export function getDefaultAss(params) {//获取
    return request({
        url: "/config/default/list.do?",
        method: 'post',
        data: params
    })
}

export function addDefaultAss(params) {
    return request({
        url: "/config/default/add.do?",
        method: 'post',
        data: params
    })
}
export function updateDefaultAss(params) {
    return request({
        url: "/config/default/update.do?",
        method: 'post',
        data: params
    })
}

export function delDefaultAss(params) {
    return request({
        url: "/config/default/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function deleteData(params){
    return request({
        url: 'config/kv/deleteById.do?',
        method: 'post',
        data:params
    })
}

export function getMappingList(params) {//获取
    return request({
        url: "config/kv/list.do?",
        method: 'post',
        data: params
    })
}

export function addMapping(params) {//添加
    return request({
        url: "config/kv/add.do?",
        method: 'post',
        data: params
    })
}
export function updateMapping(params) {//编辑
    return request({
        url: "config/kv/update.do?",
        method: 'post',
        data: params
    })
}

export function checkImport(params) {//导入前校验
    return request({
        url: "number/set/checkImport.do?",
        method: 'post',
        data: params
    })
}
export function distributeNo(params) {//分配号码
    return request({
        url: "number/set/distributeNo.do?",
        method: 'post',
        data: params
    })
}

export function cardExportExcelAsync(params) {//网卡关联导出
    return request({
        url: "config/card/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}
export function setExportExcelAsync(params) {//号码库关联导出
    return request({
        url: "config/set/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}



 







