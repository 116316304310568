<template>
    <el-form :model="formData" :rules="rules" ref="formData">
         <el-form-item label="映射对" prop="inputStr" :label-width="formLabelWidth" v-if="isNew=='0'">
            <el-input  type="textarea" v-model="formData.inputStr"  placeholder="请输入键值对形式中间以空格隔开，多行请换行输入" autocomplete="off" :disabled="isNew=='1'"></el-input>
        </el-form-item>
        <el-form-item label="原号码:" prop="k" :label-width="formLabelWidth" v-if="isNew=='1'">
            <el-input v-model="formData.k" autocomplete="off" :disabled="isNew=='1'"></el-input>
        </el-form-item>
        <el-form-item label="新号码:" prop="v" :label-width="formLabelWidth" v-if="isNew=='1'">
            <el-input v-model="formData.v" autocomplete="off"></el-input>
        </el-form-item>
       
        <div class="align-right">
        <el-button type="primary" class="themed-button" @click="cancle"
          >取消</el-button
        >
        <el-button type="primary" class="themed-button" @click="sure('formData')"
          >确认</el-button
        >
      </div>

    </el-form>
    
</template>

<script>
   import {addMapping,updateMapping} from "@/api/configurationManagement.js"
    export default {
        name: "",
        props: ['initData',"isNew"],
       
        data() {
            return {
                originData:this.initData,
                formLabelWidth: "110px",
                rules: {
                    inputStr: [{
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    },],
                     v: [{
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    },],
                },
                formData:{
                    inputStr:"",
                    k:"",
                    v:"",
                    id:""
                }
            }
        },
        mounted(){
          this.initDataList()
        },
        methods:{
            initDataList(){
            if(this.isNew=="1"){
             this.formData.k = this.initData.k
             this.formData.v = this.initData.v
             this.formData.id = this.initData.id
             this.formData.inputStr =""
            }else if(this.isNew=="0"){
                this.formData.k = ""
             this.formData.v = ""
             this.formData.id = ""
             this.formData.inputStr =""
            }

            },
            cancle(){
            this.$emit("close")
            },
            
            sure(formName){
                console.log(this.formData)
            this.$refs[formName].validate((valid) => {
           if (valid) {
            //新增
            if(this.isNew=="0"){
             addMapping(this.formData).then(res=>{
                 this.$emit("refresh")
             })

            }
            if(this.isNew=="1"){//编辑
             updateMapping(this.formData).then(res=>{
                this.$emit("refresh")
             })
            }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
           
            },
        }
    }
</script>

<style scoped>

</style>