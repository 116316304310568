<template>

<div class="recommend table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">映射管理</span>
        <el-form inline class="hook right themed-form search-form">   
            <el-form-item>
                <el-input placeholder="请输入原号码" v-model="oldNum" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addTicket">新增</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table"  :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="原号码" prop="k" show-overflow-tooltip></el-table-column>
            <el-table-column label="新号码" prop="v" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                     <el-button type="primary" class="themed-button" @click="edit(scope.row)">编辑</el-button>
                    <el-button type="danger" class="themed-button" @click="delFilter(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <el-dialog :title="title" :visible.sync="dialogBox" v-if="dialogBox" >
        <edit :initData="formdata" :isNew="isNew"   @close="dialogBox=false"  @refresh="refreshData"></edit>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
    import {getMappingList,deleteData} from "@/api/configurationManagement.js"

    import {messageBox,confirmBox } from '@/utils/common.js'
    import SearchSelect from '@/components/SearchSelect'

    import edit from '@/views/configurationManagement/configStyle/edit'
    import resize from '@/mixins/resize.js'

  
    export default {
        name:'mappingList',
        mixins:[resize],
        data(){
            return {
                tableData:[],
                pageSize:15,
                currentPage:1,
                total:0,
                dialogBox:false,
                title:"",
                formdata:{},
                isNew:"",
                oldNum:"",
            }
        },
        mounted(){
            this._getMappingList()
        },
        methods:{
            addTicket(){
                this.dialogBox = true
                this.title = "新增映射"
                this.isNew ="0"
                this.formdata.inputStr =""
                
            },
            edit(row){
                this.dialogBox = true
                this.title = "编辑映射"
                this.formdata = row
                this.isNew ="1"
            },
            delFilter(id){
               confirmBox(this,'确定要删除么？',null,()=>{
                   deleteData({id}).then((res)=>{
                       this._getMappingList()
                   })
               })  
            },
            searchData(){
                this.currentPage = 1
                this._getMappingList()
            },
            refreshData(){
                this.dialogBox = false
                this._getMappingList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val
                this._getMappingList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val
                this._getMappingList()
            },
            _getMappingList(){
                let params = {}
                params.pageSize = this.pageSize
                params.pageNo = this.currentPage
                params.k = this.oldNum
                getMappingList(params).then((res)=>{
                    console.log(res,121)
                    this.total = res.total
                    this.currentPage = res.pageNo
                    this.pageSize = res.pageSize
                    this.tableData = res.rows
                })
            }
        },
        components:{
            SearchSelect,
            edit
        }
    }
</script>

<style>
.marginR10{margin-right:10px;}
.recommend .search-form .el-input .el-input__inner {width:150px}
.recommend .el-form-item__content .el-input-group{vertical-align:middle}
.recommend .el-form-item__content .el-input-group{vertical-align:middle}
</style>
